import { useEffect, useMemo } from "react";
import { useI18n } from "@lookiero/i18n-react";
import { isValueNumberValidation } from "../../../../../../projection/question/question.typeguards";
import { useAnswersValidation } from "./useAnswersValidation";
const useQuestionValidationError = ({ domain, questionId, ref, enabled = true, }) => {
    const { formatMessage } = useI18n({ domain });
    const { scrollToError, validation } = useAnswersValidation();
    const questionValidation = useMemo(() => validation === null || validation === void 0 ? void 0 : validation.find((v) => v.questionId === questionId), [questionId, validation]);
    const { mandatory, value, ctaTriggered, triggerScrollTimestamp } = questionValidation || {};
    const error = useMemo(() => enabled && value
        ? isValueNumberValidation(value)
            ? formatMessage({ id: value.errorTranslationKey }, { min: `${value.minNumber}`, max: `${value.maxNumber}` })
            : formatMessage({ id: value.errorTranslationKey }, { min: `${value.minDate}`, max: `${value.maxDate}` })
        : mandatory && ctaTriggered
            ? formatMessage({ id: mandatory.mandatoryErrorTranslationKey })
            : undefined, [ctaTriggered, enabled, formatMessage, mandatory, value]);
    useEffect(() => {
        if (!triggerScrollTimestamp) {
            return;
        }
        scrollToError(ref);
    }, [ref, scrollToError, triggerScrollTimestamp]);
    return error;
};
export { useQuestionValidationError };
