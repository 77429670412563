import React, { useCallback } from "react";
import { CommandStatus } from "@lookiero/messaging-react";
import { i18nRoot } from "../../i18nRoot";
import { createI18nProvider } from "./I18nContext";
const i18n = ({ fetchTranslation, read, write, domain }) => {
    const I18nRoot = i18nRoot({ fetchTranslation, read, write, domain });
    const I18nProvider = createI18nProvider({ domain });
    // eslint-disable-next-line react/prop-types
    const I18n = ({ locale, children, loader, onError, includeDevTools }) => {
        // eslint-disable-next-line react/prop-types
        const I18nRootChildren = ({ locale, translation, syncStatus }) => {
            const handleI18nError = useCallback((error) => {
                if (syncStatus === CommandStatus.LOADING) {
                    return;
                }
                onError === null || onError === void 0 ? void 0 : onError(error);
            }, [syncStatus]);
            return translation ? (React.createElement(I18nProvider, { key: locale, locale: locale, 
                // eslint-disable-next-line react/prop-types
                messages: translation.messages, onError: handleI18nError }, children)) : (loader);
        };
        return (React.createElement(I18nRoot, { includeDevTools: includeDevTools, locale: locale }, I18nRootChildren));
    };
    return I18n;
};
export { i18n };
