const DOMAIN = "StyleProfileI18n";
const DATE_FORMAT_PREFIX = "date_format.";
const PREFIX = "style_profile.";
var I18nMessages;
(function (I18nMessages) {
    I18nMessages["HEADER_TITLE"] = "header.title";
    I18nMessages["HOME_TITLE"] = "main_page.title";
    I18nMessages["HOME_DESCRIPTION"] = "main_page.subtitle";
    I18nMessages["SOCIAL_SUBTITLE"] = "social.subtitle";
    I18nMessages["IMAGE_PICKER_MODAL_TITLE"] = "image_picker_modal.title";
    I18nMessages["IMAGE_PICKER_MODAL_TAKE_PHOTO_BUTTON"] = "image_picker_modal.take_photo_button";
    I18nMessages["IMAGE_PICKER_MODAL_CHOOSE_FROM_LIBRARY_BUTTON"] = "image_picker_modal.choose_from_library_button";
    I18nMessages["UPLOAD_IMAGE_TOAST_GENERIC_ERROR"] = "upload_image.toast.generic_error";
    I18nMessages["SAVE_CONFIRMATION_TOAST"] = "save_confirmation.toast";
    I18nMessages["TOAST_GENERIC_ERROR"] = "toast.generic.error";
    I18nMessages["NEXT_BEST_ACTION_TITLE"] = "next_best_action.title";
    I18nMessages["NEXT_BEST_ACTION_SUCCESS_TITLE"] = "next_best_action.success_title";
    I18nMessages["NEXT_BEST_ACTION_SUCCESS_DESCRIPTION"] = "next_best_action.success_description";
    I18nMessages["NEXT_BEST_ACTION_SUCCESS_CTA"] = "next_best_action.success_cta";
})(I18nMessages || (I18nMessages = {}));
export { DOMAIN, I18nMessages, DATE_FORMAT_PREFIX, PREFIX };
