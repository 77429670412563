import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { useParams } from "react-router-native";
import { Text, Spinner } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { QueryStatus } from "@lookiero/messaging-react";
import { Body } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { useViewStyleProfileAnswersByCustomerId } from "../../../projection/styleProfileAnswers/react/useViewStyleProfileAnswersByCustomerId";
import { useViewStyleProfileDefinitionByCustomerId } from "../../../projection/styleProfileDefinition/react/useViewStyleProfileDefinitionByCustomerId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { HomeHeader } from "../../components/templates/header/homeHeader/HomeHeader";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { DOMAIN, I18nMessages, PREFIX } from "../../i18n/i18n";
import { style } from "./Home.style";
import { Questions } from "./components/questions/Questions";
const Home = ({ layout: Layout, lookAndLike: LookAndLike, onBack, children }) => {
    const { slug } = useParams();
    const screenSize = useScreenSize();
    const titleText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.HOME_TITLE, prefix: PREFIX });
    const descriptionText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.HOME_DESCRIPTION, prefix: PREFIX });
    const { customer: { customerId, country, segment }, } = useStaticInfo();
    const [questions] = useViewStyleProfileDefinitionByCustomerId({ customerId });
    const [answers, answersStatus] = useViewStyleProfileAnswersByCustomerId({ customerId });
    const [scrollEnabled, setScrollEnabled] = useState(true);
    const disableScroll = useCallback(() => setScrollEnabled(false), []);
    const enableScroll = useCallback(() => setScrollEnabled(true), []);
    const answersLoaded = answersStatus === QueryStatus.SUCCESS || Boolean(answers);
    const displayLookAndLike = Platform.OS === "web" && Boolean(answers);
    const [lookAndLikeRenderedError, setLookAndLikeRenderedError] = useState(false);
    const [lookAndLikeRendered, setLookAndLikeRendered] = useState(false);
    const handleOnLookAndLikeFetched = useCallback(() => setLookAndLikeRendered(true), []);
    const handleOnLookAndLikeError = useCallback(() => {
        setLookAndLikeRendered(true);
        setLookAndLikeRenderedError(true);
    }, []);
    useTrackPageView({
        country,
        customerId,
        page: TrackingPage.STYLE,
        segment,
    });
    useEffect(() => setScrollEnabled(!Boolean(slug)), [slug]);
    if (!answersLoaded) {
        return React.createElement(Spinner, { testID: "spinner" });
    }
    return (React.createElement(Fragment, null,
        React.createElement(Layout, { header: Platform.OS === "web" ? React.createElement(HomeHeader, { onBack: onBack }) : undefined, scrollEnabled: scrollEnabled },
            React.createElement(Body, null,
                displayLookAndLike && (React.createElement(View, { style: style.bodyRow },
                    React.createElement(LookAndLike, { customerId: customerId, style: style.lookAndLike, view: "style-profile", onBlur: enableScroll, onError: handleOnLookAndLikeError, onFetched: handleOnLookAndLikeFetched, onFocus: disableScroll }))),
                (!displayLookAndLike || lookAndLikeRendered) && (React.createElement(React.Fragment, null,
                    displayLookAndLike && !lookAndLikeRenderedError && screenSize === "S" && React.createElement(View, { style: style.divider }),
                    React.createElement(View, { style: style.bodyRow },
                        React.createElement(View, { style: style.content },
                            React.createElement(Text, { level: 3, style: style.title, heading: true }, titleText),
                            React.createElement(Text, { level: 3, style: style.description }, descriptionText)),
                        React.createElement(View, { style: style.bodyGap }, questions && answers ? (React.createElement(Questions, { answers: answers, answersStatus: answersStatus, questions: questions })) : (React.createElement(Spinner, { testID: "spinner" })))))))),
        children));
};
export { Home };
