/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Pressable, View } from "react-native";
import { generatePath, useNavigate } from "react-router-native";
import { COLOR, Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswersForQuestion } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useViewScreenBySlugAndCustomerId } from "../../../../../projection/screen/react/useViewScreenBySlugAndCustomerId";
import { useStaticInfo } from "../../../../hooks/useStaticInfo";
import { DOMAIN } from "../../../../i18n/i18n";
import { Routes } from "../../../../routing/routes";
import { EMPTY_ANSWER, useQuestionAnswers } from "../../behaviours/useQuestionAnswers";
import { style } from "./HostScreenQuestionItem.style";
const HostScreenQuestionItem = ({ question }) => {
    const { customer: { customerId }, } = useStaticInfo();
    const navigate = useNavigate();
    const questionTitle = useI18nMessage({ domain: DOMAIN, id: question.translationKey });
    const { answers } = useAnswersForQuestion({ question });
    const answer = useQuestionAnswers({ question, answers });
    const showIconNotification = answer === EMPTY_ANSWER;
    const navigateToScreen = useCallback(() => navigate(generatePath(Routes.SCREEN, { slug: question.metadata.slug })), [navigate, question.metadata.slug]);
    /**
     * Pre-load screen projection so that when the Screen route is mounted, the projection is already fetch.
     */
    useViewScreenBySlugAndCustomerId({ customerId, slug: question.metadata.slug, experiment: undefined });
    return (React.createElement(Pressable, { style: style.question, testID: question.id, onPress: navigateToScreen },
        React.createElement(View, { style: style.questionAndAnswer },
            React.createElement(Text, { level: 1, detail: true, ellipsizeMode: true }, questionTitle),
            React.createElement(Text, { level: 1, style: style.answer, detail: true, ellipsizeMode: true }, answer)),
        React.createElement(View, { style: style.iconContainer },
            React.createElement(Icon, { color: COLOR.ICON_LIGHT, name: "arrow_right_small" }),
            showIconNotification && React.createElement(View, { style: style.notification, testID: "host-screen-question-item-notification" }))));
};
export { HostScreenQuestionItem };
