import React from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { style } from "./QuestionTitle.style";
const textMainProps = {
    heading: true,
    level: 3,
};
const textDefaultProps = {
    body: true,
    level: 3,
};
const QuestionTitle = ({ domain, level, question, style: customStyle }) => {
    const titleText = useI18nMessage({ domain, id: question.translationKey });
    const isMain = level === 0;
    const props = isMain ? textMainProps : textDefaultProps;
    return (React.createElement(Text, { style: [style.text, isMain && style.textMain, customStyle], ...props }, titleText));
};
export { QuestionTitle };
