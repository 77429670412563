import React from "react";
import { View } from "react-native";
import { ALIGN, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { ButtonIcon } from "@lookiero/sty-psp-ui";
import { DOMAIN, I18nMessages, PREFIX } from "../../../../i18n/i18n";
import { Header } from "../Header";
import { style } from "./HomeHeader.style";
const HomeHeader = ({ onBack }) => {
    const titleText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.HEADER_TITLE, prefix: PREFIX });
    return (React.createElement(Header, { testID: "home-header" },
        React.createElement(ButtonIcon, { name: "arrow_left", testID: "back-button-icon", onPress: onBack }),
        React.createElement(Text, { align: ALIGN.CENTER, level: 3, action: true }, titleText),
        React.createElement(View, { style: style.buttonIconPlaceholder })));
};
export { HomeHeader };
