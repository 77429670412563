/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { InputField } from "@lookiero/sty-psp-ui";
import { useFocusedInput } from "../../../../../hooks/useFocusedInput";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { style } from "./HostInputPillsQuestionItem.style";
import { Pill } from "./components/pill/Pill";
import { TextInputAction } from "./components/textInputAction/TextInputAction";
const HostInputPillsQuestionItem = ({ domain, question }) => {
    const label = useI18nMessage({ domain, id: question.translationKey });
    const action = useI18nMessage({ domain, id: question.metadata.ctaTranslationKey });
    const error = useI18nMessage({ domain, id: question.metadata.errorTranslationKey });
    const textInputRef = useRef(null);
    const [value, setValue] = useState("");
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const validationError = useMemo(() => (value && (answer === null || answer === void 0 ? void 0 : answer.length) === question.metadata.maxAnswers ? error : undefined), [answer === null || answer === void 0 ? void 0 : answer.length, error, question.metadata.maxAnswers, value]);
    const handleOnChange = useCallback((value) => setValue(value), []);
    const handleOnPressAction = useCallback(() => {
        var _a;
        const newValue = value ? `${value}` : "";
        onChange({ questionId: newValue, answer: newValue });
        setValue("");
        (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [onChange, value]);
    const handleOnPressPill = useCallback((value) => onChange({ questionId: value, answer: undefined }), [onChange]);
    const { focus, blur } = useFocusedInput();
    const handleOnFocus = useCallback((onFocus) => () => {
        onFocus();
        focus({ ref: textInputRef });
    }, [focus]);
    const handleOnBlur = useCallback((onBlur) => () => {
        onBlur();
        blur();
    }, [blur]);
    return (React.createElement(View, { style: style.container, testID: "host-input-pills-question-item" },
        React.createElement(InputField, { error: validationError, label: label, value: value, input: ({ onBlur, onFocus, style }) => (React.createElement(TextInputAction, { ref: textInputRef, action: action, error: Boolean(validationError), maxLength: question.metadata.maxCharacters, style: style, value: value, onBlur: handleOnBlur(onBlur), onChange: handleOnChange, onFocus: handleOnFocus(onFocus), onPress: handleOnPressAction })) }),
        React.createElement(View, { style: style.pillsContainer }, answer === null || answer === void 0 ? void 0 : answer.map((label, index) => (React.createElement(Pill, { key: index, label: label, style: answer.length > 1 && index !== answer.length - 1 && style.pillGap, onPress: () => handleOnPressPill(label) }))))));
};
export { HostInputPillsQuestionItem };
