import React from "react";
import { View } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withSpring } from "react-native-reanimated";
import { Button, BUTTON_VARIANT, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { DOMAIN, I18nMessages, PREFIX } from "../../../../i18n/i18n";
import { style } from "./NextBestActionSuccess.style";
import { Tick } from "./Tick";
const ANIMATION_TRANSLATE_Y = 30;
const NextBestActionSuccess = ({ onNavigateToStyleProfile }) => {
    const title = useI18nMessage({ domain: DOMAIN, id: I18nMessages.NEXT_BEST_ACTION_SUCCESS_TITLE, prefix: PREFIX });
    const description = useI18nMessage({
        domain: DOMAIN,
        id: I18nMessages.NEXT_BEST_ACTION_SUCCESS_DESCRIPTION,
        prefix: PREFIX,
    });
    const cta = useI18nMessage({ domain: DOMAIN, id: I18nMessages.NEXT_BEST_ACTION_SUCCESS_CTA, prefix: PREFIX });
    const textAnimation = useSharedValue(0);
    textAnimation.value = withSpring(1);
    const textAnimatedStyles = useAnimatedStyle(() => ({
        opacity: textAnimation.value,
        transform: [{ translateY: ANIMATION_TRANSLATE_Y * (1 - textAnimation.value) }],
    }), [textAnimation]);
    const ctaAnimation = useSharedValue(0);
    ctaAnimation.value = withDelay(100, withSpring(1));
    const ctaAnimatedStyles = useAnimatedStyle(() => ({
        opacity: ctaAnimation.value,
        transform: [{ translateY: ANIMATION_TRANSLATE_Y * (1 - ctaAnimation.value) }],
    }), [ctaAnimation]);
    return (React.createElement(View, { style: style.container, testID: "next-best-action-success" },
        React.createElement(Tick, null),
        React.createElement(Animated.View, { style: [style.textContainer, textAnimatedStyles] },
            React.createElement(Text, { level: 3, style: style.title, heading: true }, title),
            React.createElement(Text, { level: 1, style: style.description, detail: true }, description)),
        React.createElement(Animated.View, { style: [style.ctaContainer, ctaAnimatedStyles] },
            React.createElement(Button, { testID: "next-best-action-success-cta", variant: BUTTON_VARIANT.SECONDARY, small: true, wide: true, onPress: onNavigateToStyleProfile }, cta))));
};
export { NextBestActionSuccess };
