import React from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage, useI18n } from "@lookiero/i18n-react";
import { questionById } from "../../../behaviours/questionById";
import { useAnswers, useAnswerForId } from "../../../behaviours/useAnswers";
import { style } from "./HostScreenWithSummaryAnswers.style";
const HostScreenWithSummaryAnswers = ({ domain, question, style: customStyle, }) => {
    const { questionsFilteredByAnswers } = useAnswers();
    const { answer } = useAnswerForId({ id: question.id });
    const questions = (answer === null || answer === void 0 ? void 0 : answer.reduce((acc, answer) => {
        const question = questionById({ questionId: answer, questions: questionsFilteredByAnswers });
        return [...acc, ...(question ? [question] : [])];
    }, [])) || [];
    const { formatMessage } = useI18n({ domain });
    const titleText = useI18nMessage({ domain, id: question.metadata.summaryTranslationKey });
    return answer ? (React.createElement(View, { style: customStyle, testID: "host-screen-with-summary-answers" },
        React.createElement(Text, { level: 3, style: style.title, action: true }, titleText),
        questions.map((question) => (React.createElement(Text, { key: question.id, level: 3, style: style.answer, testID: question.id }, formatMessage({ id: question.translationKey })))))) : null;
};
export { HostScreenWithSummaryAnswers };
