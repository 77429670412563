import React from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { DOMAIN, I18nMessages, PREFIX } from "../../../../../../../i18n/i18n";
import { style } from "./StyleProfileSocialDescription.style";
const StyleProfileSocialDescription = () => {
    const description = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SOCIAL_SUBTITLE, prefix: PREFIX });
    return (React.createElement(Text, { level: 3, style: style.description }, description));
};
export { StyleProfileSocialDescription };
