/* eslint-disable react/prop-types */
import React from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { style } from "./StyleDescription.style";
const StyleDescription = ({ domain, i18nPrefix }) => {
    const description = useI18nMessage({ domain, prefix: i18nPrefix, id: I18nMessages.IDEAL_STYLES_SUBTITLE });
    return (React.createElement(Text, { level: 3, style: style.description }, description));
};
export { StyleDescription };
