import React from "react";
import { HostScreenQuestionItem } from "../hostScreenQuestionItem/HostScreenQuestionItem";
import { style } from "./HostScreenWithSummaryQuestionItem.style";
import { HostScreenWithSummaryAnswers } from "./hostScreenWithSummaryAnswers/HostScreenWithSummaryAnswers";
const HostScreenWithSummaryQuestionItem = ({ domain, question, questionParentId, level, children, cta, }) => {
    return (React.createElement(HostScreenQuestionItem, { cta: cta, domain: domain, level: level, question: question, questionParentId: questionParentId, testID: "host-screen-with-summary-question-item" },
        children,
        React.createElement(HostScreenWithSummaryAnswers, { domain: domain, question: question, style: style.summary })));
};
export { HostScreenWithSummaryQuestionItem };
