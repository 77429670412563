/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { InputField, NumberInput } from "@lookiero/sty-psp-ui";
import { useFocusedInput } from "../../../../../hooks/useFocusedInput";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
const HostWeightMetricQuestionItem = ({ domain, question }) => {
    const label = useI18nMessage({ domain, id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const ref = useRef(null);
    const [enabledQuestionValidationError, setEnabledQuestionValidationError] = useState(false);
    const validationError = useQuestionValidationError({
        domain,
        questionId: question.id,
        ref,
        enabled: enabledQuestionValidationError,
    });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value ? `${value}` : "" });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const inputRef = useRef(null);
    const { focus, blur } = useFocusedInput();
    const handleOnFocus = useCallback((onFocus) => () => {
        onFocus();
        focus({ ref: inputRef });
    }, [focus]);
    const handleOnBlur = useCallback((onBlur) => () => {
        onBlur();
        blur();
        setEnabledQuestionValidationError(Boolean(selectedAnswer));
    }, [blur, selectedAnswer]);
    return (React.createElement(View, { ref: ref, testID: "host-weight-metric-question-item" },
        React.createElement(InputField, { error: validationError, label: label, value: selectedAnswer, input: ({ onBlur, onFocus, style }) => (React.createElement(NumberInput, { ref: inputRef, error: Boolean(validationError), maxLength: 6, style: style, unit: "kg", value: selectedAnswer, onBlur: handleOnBlur(onBlur), onChange: handleOnChange, onFocus: handleOnFocus(onFocus) })) })));
};
export { HostWeightMetricQuestionItem };
