import React, { useRef } from "react";
import { Platform, View } from "react-native";
import { useFocusedInput } from "../../../../../hooks/useFocusedInput";
import { useQuestionValidationMaxAnswers } from "../../behaviours/useQuestionValidationMaxAnswers";
import { HostScreenDescription } from "../hostScreenDescription/HostScreenDescription";
import { QuestionTitle } from "../questionTitlte/QuestionTitle";
import { style } from "./HostScreenQuestionItem.style";
const HostScreenQuestionItem = ({ domain, question, level, children, cta, testID = "host-screen-question-item", }) => {
    const ctaRef = useRef(null);
    useQuestionValidationMaxAnswers({ questionId: question.id, ref: ctaRef });
    const { isKeyboardOpened } = useFocusedInput();
    const containerStyle = Platform.OS === "web" && isKeyboardOpened && style.contentKeyboard;
    const titleStyle = question.metadata.descriptionId ? style.title : undefined;
    return (React.createElement(View, { style: [style.content, containerStyle], testID: testID },
        React.createElement(View, null,
            React.createElement(QuestionTitle, { domain: domain, level: level, question: question, style: titleStyle }),
            question.metadata.descriptionId && (React.createElement(View, { style: style.description },
                React.createElement(HostScreenDescription, { id: question.metadata.descriptionId }))),
            children),
        React.createElement(View, { ref: ctaRef, style: [style.ctaContainer, style.contentGap] }, cta)));
};
export { HostScreenQuestionItem };
