/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { questionWithTranslationKey } from "../../../../../../../projection/question/question.typeguards";
import { QuestionTitle } from "../questionTitlte/QuestionTitle";
import { style } from "./HostDefaultQuestionItem.style";
const HostDefaultQuestionItem = ({ domain, question, level, children }) => (React.createElement(View, { style: style.container, testID: "host-default-question-item" },
    questionWithTranslationKey(question) && React.createElement(QuestionTitle, { domain: domain, level: level, question: question }),
    children));
export { HostDefaultQuestionItem };
