/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import { Platform, View } from "react-native";
import { Link as AuroraLink, Text } from "@lookiero/aurora";
import { useI18nMessage, useI18n } from "@lookiero/i18n-react";
import { Icon, Modal } from "@lookiero/sty-psp-ui";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { SILHOUETTE_GUIDE } from "./SilhouetteGuide.contants";
import { style } from "./SilhouetteGuide.style";
const SilhouetteGuide = ({ domain, i18nPrefix, portalHostName, onOpenSilhouetteGuide }) => {
    const [visible, setVisible] = useState(false);
    const handleOnOpen = useCallback(() => {
        setVisible(true);
        onOpenSilhouetteGuide === null || onOpenSilhouetteGuide === void 0 ? void 0 : onOpenSilhouetteGuide();
    }, [onOpenSilhouetteGuide]);
    const handleOnClose = useCallback(() => setVisible(false), []);
    const Link = useMemo(() => (Platform.OS === "web" ? AuroraLink : Text), []);
    const { formatMessage } = useI18n({ domain });
    const description = useI18nMessage({ domain, prefix: i18nPrefix, id: I18nMessages.SILHOUETTE_SILHOUETTE_SUBTITLE });
    const descriptionLinkText = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SILHOUETTE_GUIDE_LINK,
    });
    const descriptionLink = formatMessage({ id: `${i18nPrefix}${I18nMessages.SILHOUETTE_SILHOUETTE_GUIDE}` }, {
        link: (React.createElement(Link, { testID: "silhouette-guide-link", underlined: true, onPress: handleOnOpen }, descriptionLinkText)),
    });
    const modalTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SILHOUETTE_GUIDE_MODAL_TITLE,
    });
    const silhouetteGuide = Object.entries(SILHOUETTE_GUIDE);
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { level: 3, style: style.description }, description),
        React.createElement(Text, { level: 3, style: style.description }, descriptionLink),
        React.createElement(Modal, { portalHostName: portalHostName, testID: "silhouette-guide-modal", visible: visible, scroll: true, showCloseButton: true, onClose: handleOnClose },
            React.createElement(View, { style: style.modal },
                React.createElement(Text, { level: 1, action: true }, modalTitle),
                React.createElement(View, { style: style.modalGap }, silhouetteGuide.map(([key, { icon, title, description }], index) => (React.createElement(View, { key: key, style: [style.contentView, index !== 0 && style.contentGap] },
                    React.createElement(Icon, { name: icon }),
                    React.createElement(View, { style: [style.contentViewColumn, style.contentViewGap] },
                        React.createElement(Text, { level: 3, action: true }, formatMessage({ id: `${i18nPrefix}${title}` })),
                        React.createElement(Text, { level: 3, style: style.description }, formatMessage({ id: `${i18nPrefix}${description}` })))))))))));
};
export { SilhouetteGuide };
