/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Pressable } from "react-native";
import { Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { AnimatedChecked } from "../animatedChecked/AnimatedChecked";
import { style } from "./ButtonWithIconQuestionItem.style";
const ICON = {
    ["quiz.icon.dislike"]: "dislike",
    ["style_profile.icon.dislike"]: "dislike",
};
const ButtonWithIconQuestionItem = ({ domain, question, questionParentId, }) => {
    const titleText = useI18nMessage({ domain, id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    const checked = state === QuestionItemState.SELECTED;
    const icon = ICON[question.metadata.icon];
    return (React.createElement(Pressable, { style: style.button, testID: "button-with-icon-question-item", onPress: handleOnChange },
        React.createElement(Icon, { name: icon }),
        React.createElement(Text, { level: 1, style: style.text, testID: question.id, detail: true }, titleText),
        React.createElement(AnimatedChecked, { checked: checked })));
};
export { ButtonWithIconQuestionItem };
