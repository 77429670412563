import { createIntl, createIntlCache, DEFAULT_INTL_CONFIG } from "@formatjs/intl";
import React, { createContext, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { processIntlConfig } from "./utils/processIntlConfig";
const contexts = {};
const useI18n = ({ domain }) => {
    invariant(contexts[domain], `There is no bootstrapped i18n context for: ${domain}`);
    const I18nContext = contexts[domain];
    const intl = useContext(I18nContext);
    return intl;
};
const createI18nProvider = ({ domain }) => {
    invariant(!contexts[domain], `There is already bootstrapped a i18n context for: ${domain}`);
    const domainContext = createContext(null);
    contexts[domain] = domainContext;
    const { Provider } = domainContext;
    // eslint-disable-next-line react/prop-types
    const I18nProvider = ({ children, ...customConfig }) => {
        const cache = useMemo(() => createIntlCache(), []);
        const intl = useMemo(() => createIntl(processIntlConfig({ config: { ...DEFAULT_INTL_CONFIG, ...customConfig } }), cache), [cache, customConfig]);
        invariant(intl, "Intl is not initialized");
        return React.createElement(Provider, { value: intl }, children);
    };
    return I18nProvider;
};
export { createI18nProvider, useI18n };
