import React, { Fragment, memo } from "react";
import { Divider } from "@lookiero/aurora";
import { questionHasChildren } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useQuestionItem } from "@lookiero/sty-psp-quiz-style-profile-common-ui/dist/src/infrastructure/ui/components/organisms/question/behaviours/useQuestionItem";
import { DOMAIN } from "../../../../i18n/i18n";
import { style } from "./Question.style";
const Question = ({ question, questionParentId }) => {
    const Item = useQuestionItem({ type: question.type });
    return (React.createElement(Item, { domain: DOMAIN, question: question, questionParentId: questionParentId }, questionHasChildren(question) &&
        question.children.map((childQuestion, index) => (React.createElement(Fragment, { key: childQuestion.id },
            React.createElement(Question, { question: childQuestion, questionParentId: question.id }),
            index < question.children.length - 1 && React.createElement(Divider, { style: style.divider }))))));
};
const memoizedQuestion = memo(Question);
export { memoizedQuestion as Question };
