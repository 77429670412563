/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { InputField, NumberInput } from "@lookiero/sty-psp-ui";
import { useFocusedInput } from "../../../../../hooks/useFocusedInput";
import { joinImperialHeightString, splitImperialHeightString } from "../../behaviours/mapAnswersToImperialSystem";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
import { style } from "./HostHeightImperialQuestionItem.style";
const HostHeightImperialQuestionItemInput = ({ value, error, style: customStyle, onFocus, onBlur, onChange, onBlurred, }) => {
    const { feet: initialFeet, inches: initialInches } = splitImperialHeightString({ height: value });
    const [feet, setFeet] = useState(initialFeet);
    const [inches, setInches] = useState(initialInches);
    const isFocused = useRef(false);
    const feetInputRef = useRef(null);
    const inchesInputRef = useRef(null);
    const handleOnFeetChange = useCallback((value) => {
        setFeet(value);
        const height = joinImperialHeightString({ feet: value, inches });
        onChange(height === "/" ? undefined : height);
    }, [inches, onChange]);
    const handleOnInchesChange = useCallback((value) => {
        setInches(value);
        const height = joinImperialHeightString({ feet, inches: value });
        onChange(height === "/" ? undefined : height);
    }, [feet, onChange]);
    const { focus, blur } = useFocusedInput();
    const feetFocusedRef = useRef(false);
    const inchesFocusedRef = useRef(false);
    const handleOnFeetFocus = useCallback((onFocus) => () => {
        onFocus();
        focus({ ref: feetInputRef });
        feetFocusedRef.current = true;
    }, [focus]);
    const handleOnFeetBlur = useCallback((onBlur) => () => {
        onBlur();
        feetFocusedRef.current = false;
        setTimeout(() => {
            if (!inchesFocusedRef.current) {
                blur();
                onBlurred();
                if (!feet && !inches) {
                    isFocused.current = false;
                }
            }
        }, 100);
    }, [blur, feet, inches, inchesFocusedRef, onBlurred]);
    const handleOnInchesFocus = useCallback((onFocus) => () => {
        var _a;
        onFocus();
        focus({ ref: inchesInputRef });
        inchesFocusedRef.current = true;
        if (!isFocused.current && !feet) {
            (_a = feetInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        isFocused.current = true;
    }, [feet, focus]);
    const handleOnInchesBlur = useCallback((onBlur) => () => {
        onBlur();
        setTimeout(() => {
            inchesFocusedRef.current = false;
            if (!feetFocusedRef.current) {
                blur();
                onBlurred();
                if (!feet && !inches) {
                    isFocused.current = false;
                }
            }
        }, 100);
    }, [blur, feet, inches, onBlurred]);
    const handleOnFeetComplete = useCallback(() => {
        var _a;
        if (!isFocused.current) {
            return;
        }
        (_a = inchesInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (React.createElement(View, { style: style.inputContainer },
        React.createElement(NumberInput, { ref: feetInputRef, error: error, maxLength: 3, style: { container: [customStyle === null || customStyle === void 0 ? void 0 : customStyle.container, style.inputFt], input: customStyle === null || customStyle === void 0 ? void 0 : customStyle.input }, testID: "host-height-imperial-question-item-feet-input", unit: "ft", value: feet, onBlur: handleOnFeetBlur(onBlur), onChange: handleOnFeetChange, onComplete: handleOnFeetComplete, onFocus: handleOnFeetFocus(onFocus) }),
        React.createElement(NumberInput, { ref: inchesInputRef, error: error, maxLength: 3, style: { container: [customStyle === null || customStyle === void 0 ? void 0 : customStyle.container, style.inputIn], input: customStyle === null || customStyle === void 0 ? void 0 : customStyle.input }, testID: "host-height-imperial-question-item-inches-input", unit: "in", value: inches, onBlur: handleOnInchesBlur(onBlur), onChange: handleOnInchesChange, onFocus: handleOnInchesFocus(onFocus) })));
};
const HostHeightImperialQuestionItem = ({ domain, question }) => {
    const label = useI18nMessage({ domain, id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const ref = useRef(null);
    const [enabledQuestionValidationError, setEnabledQuestionValidationError] = useState(false);
    const validationError = useQuestionValidationError({
        domain,
        questionId: question.id,
        ref,
        enabled: enabledQuestionValidationError,
    });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const handleOnBlurred = useCallback(() => setEnabledQuestionValidationError(Boolean(selectedAnswer)), [selectedAnswer]);
    return (React.createElement(View, { ref: ref, testID: "host-height-imperial-question-item" },
        React.createElement(InputField, { error: validationError, label: label, value: selectedAnswer, input: ({ onBlur, onFocus, style }) => (React.createElement(HostHeightImperialQuestionItemInput, { error: Boolean(validationError), style: style, value: selectedAnswer, onBlur: onBlur, onBlurred: handleOnBlurred, onChange: handleOnChange, onFocus: onFocus })) })));
};
export { HostHeightImperialQuestionItem };
