import React, { useCallback, useMemo } from "react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { isModalNotification, isToastNotification } from "../../../projection/notification/notification";
import { useRemoveNotification } from "../../domain/notification/react/useRemoveNotification";
import { useListNotifications } from "../../projection/notification/react/useListNotifications";
import { ModalNotifications } from "../components/modalNotifications/ModalNotifications";
import { ToastNotifications } from "../components/toastNotifications/ToastNotifications";
const Notifications = ({ domain, contextId, style: customStyle, portalHostName }) => {
    const logger = useLogger();
    const [notifications] = useListNotifications({ contextId });
    const [remove] = useRemoveNotification({ contextId, logger });
    const onRemove = useCallback((id) => remove({ aggregateId: id }), [remove]);
    const toastNotifications = useMemo(() => notifications === null || notifications === void 0 ? void 0 : notifications.filter(isToastNotification), [notifications]);
    const modalNotifications = useMemo(() => notifications === null || notifications === void 0 ? void 0 : notifications.filter(isModalNotification), [notifications]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ToastNotifications, { domain: domain, notifications: toastNotifications, style: customStyle === null || customStyle === void 0 ? void 0 : customStyle.toast, onRemove: onRemove }),
        React.createElement(ModalNotifications, { domain: domain, notifications: modalNotifications, portalHostName: portalHostName, style: customStyle === null || customStyle === void 0 ? void 0 : customStyle.modal, onRemove: onRemove })));
};
export { Notifications };
