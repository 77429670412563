/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import { Platform, View } from "react-native";
import { Link as AuroraLink, Table, Text } from "@lookiero/aurora";
import { useI18nMessage, useI18n } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { style } from "./SizeGuide.style";
import { SIZES } from "./SizeQuide.constants";
const SizeGuide = ({ domain, i18nPrefix, portalHostName, onOpenSizeGuide }) => {
    const [visible, setVisible] = useState(false);
    const handleOnOpen = useCallback(() => {
        setVisible(true);
        onOpenSizeGuide === null || onOpenSizeGuide === void 0 ? void 0 : onOpenSizeGuide();
    }, [onOpenSizeGuide]);
    const handleOnClose = useCallback(() => setVisible(false), []);
    const Link = useMemo(() => (Platform.OS === "web" ? AuroraLink : Text), []);
    const { formatMessage, locale } = useI18n({ domain });
    const description = useI18nMessage({ domain, prefix: i18nPrefix, id: I18nMessages.SILHOUETTE_SIZES_SUBTITLE });
    const descriptionLinkText = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_LINK,
    });
    const descriptionLink = formatMessage({ id: `${i18nPrefix}${I18nMessages.SILHOUETTE_SIZES_GUIDE_DESCRIPTION}` }, {
        link: (React.createElement(Link, { testID: "size-guide-link", underlined: true, onPress: handleOnOpen }, descriptionLinkText)),
    });
    const modalTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_MODAL_TITLE,
    });
    const sizeTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_SIZE_HEADER_TITLE,
    });
    const chestTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_CHEST_HEADER_TITLE,
    });
    const waistTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_WAIST_HEADER_TITLE,
    });
    const hipTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_HIP_HEADER_TITLE,
    });
    const howtoSubtitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_HOWTO_TITLE,
    });
    const howtoChestTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_CHEST_HOWTO_TITLE,
    });
    const howtoChestText = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_CHEST_HOWTO_TEXT,
    });
    const howtoWaistTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_WAIST_HOWTO_TITLE,
    });
    const howtoWaistText = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_WAIST_HOWTO_TEXT,
    });
    const howtoHipTitle = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_HIP_HOWTO_TITLE,
    });
    const howtoHipText = useI18nMessage({
        domain,
        prefix: i18nPrefix,
        id: I18nMessages.SILHOUETTE_SIZES_GUIDE_HIP_HOWTO_TEXT,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { level: 3, style: style.description }, description),
        React.createElement(Text, { level: 3, style: style.description }, descriptionLink),
        React.createElement(Modal, { portalHostName: portalHostName, testID: "size-guide-modal", visible: visible, scroll: true, showCloseButton: true, onClose: handleOnClose },
            React.createElement(View, { style: style.modal },
                React.createElement(Text, { level: 1, action: true }, modalTitle),
                React.createElement(Table
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                , { 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    dataSource: SIZES[locale] || SIZES.default, style: style.modalGap, schema: {
                        size: sizeTitle,
                        bust: chestTitle,
                        waist: waistTitle,
                        hip: hipTitle,
                    } }),
                React.createElement(Text, { level: 1, style: style.modalGap, action: true }, howtoSubtitle),
                React.createElement(View, { style: style.modalGap },
                    React.createElement(Text, { level: 3, action: true }, howtoChestTitle),
                    React.createElement(Text, { level: 3, style: style.description }, howtoChestText)),
                React.createElement(View, { style: style.modalGap },
                    React.createElement(Text, { level: 3, action: true }, howtoWaistTitle),
                    React.createElement(Text, { level: 3, style: style.description }, howtoWaistText)),
                React.createElement(View, { style: style.modalGap },
                    React.createElement(Text, { level: 3, action: true }, howtoHipTitle),
                    React.createElement(Text, { level: 3, style: style.description }, howtoHipText))))));
};
export { SizeGuide };
