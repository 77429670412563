const processIntlConfig = ({ config: { locale, timeZone, fallbackOnEmptyString, formats, textComponent, messages, defaultLocale, defaultFormats, onError, onWarn, wrapRichTextChunksInFragment, defaultRichTextElements, }, }) => ({
    locale,
    timeZone,
    fallbackOnEmptyString,
    formats,
    textComponent,
    messages,
    defaultLocale,
    defaultFormats,
    onError,
    onWarn,
    wrapRichTextChunksInFragment,
    defaultRichTextElements,
});
export { processIntlConfig };
