import React, { useCallback, useEffect, useRef } from "react";
import { View } from "react-native";
import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { style } from "./ModalNotificationItem.style";
const ModalNotificationItem = ({ domain, visible, notification, testID = "modal-notification-item", portalHostName, onRemove, }) => {
    const titleText = useI18nMessage({ domain, id: notification.titleI18nKey });
    const bodyText = useI18nMessage({ domain, id: notification.bodyI18nKey });
    const acceptText = useI18nMessage({ domain, id: notification.acceptI18nKey });
    const modalNotificationClosedRef = useRef(false);
    const handleOnClose = useCallback(() => {
        onRemove(notification.id);
        modalNotificationClosedRef.current = true;
    }, [notification.id, onRemove]);
    /**
     * Remove modal notification when this view is unmounted
     */
    useEffect(() => () => {
        if (!modalNotificationClosedRef.current) {
            onRemove(notification.id);
        }
    }, [notification.id, onRemove]);
    return (React.createElement(Modal, { portalHostName: portalHostName, testID: testID, visible: visible, onClose: handleOnClose },
        React.createElement(View, { style: style.modal },
            React.createElement(Text, { level: 3, style: style.title, heading: true }, titleText),
            React.createElement(Text, { level: 3, style: style.description }, bodyText),
            React.createElement(Button, { testID: "modal-notification-button", onPress: handleOnClose }, acceptText))));
};
export { ModalNotificationItem };
