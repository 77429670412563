/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Image, View } from "react-native";
import { COLOR, Text, Touchable } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { style } from "./StrikeOutButtonWithPrintQuestionItem.style";
const StrikeOutButtonWithPrintQuestionItem = ({ domain, question, questionParentId, }) => {
    const titleText = useI18nMessage({ domain, id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    const checked = state === QuestionItemState.SELECTED;
    const backgroundColor = checked ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE;
    return (React.createElement(Touchable, { backgroundColor: backgroundColor, style: style.button, testID: "strike-out-button-with-print-question-item", wide: true, onPress: handleOnChange },
        React.createElement(View, null,
            React.createElement(Image, { resizeMode: "contain", style: style.print, source: {
                    uri: question.metadata.print,
                } }),
            checked && React.createElement(View, { style: style.strikeOut })),
        React.createElement(Text, { level: 1, lineThrough: checked, style: style.text, testID: question.id, detail: true }, titleText)));
};
export { StrikeOutButtonWithPrintQuestionItem };
