/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useQuestionValidationError, useAnswerForId, useAnswersValidation, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { InputField, TextInput } from "@lookiero/sty-psp-ui";
const SOCIAL_NETWORKS_INPUT_ICON = {
    ["style_profile.icon.instagram"]: "social_instagram",
    ["style_profile.icon.pinterest"]: "social_pinterest",
};
const SocialNetworksInputQuestionItem = ({ domain, question }) => {
    const label = useI18nMessage({ domain, id: question.translationKey });
    const hint = useI18nMessage({ domain, id: question.metadata.hint });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = (answer && answer[0]) || "";
    const ref = useRef(null);
    const validationError = useQuestionValidationError({ domain, questionId: question.id, ref });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value ? `${value}` : "" });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const icon = SOCIAL_NETWORKS_INPUT_ICON[question.metadata.icon];
    return (React.createElement(View, { ref: ref, testID: "social-nertworks-input-question-item" },
        React.createElement(InputField, { error: validationError, hint: hint, icon: icon, label: label, value: selectedAnswer, input: ({ onBlur, onFocus, style }) => (React.createElement(TextInput, { autoCapitalize: "none", style: style, testID: "social-input", value: selectedAnswer, onBlur: onBlur, onChange: handleOnChange, onFocus: onFocus })) })));
};
export { SocialNetworksInputQuestionItem };
